import React from "react";
import AboutComps from "../layouts/about";

export default function About() {
  return (
    <>
      <AboutComps />
    </>
  );
}

import React from "react";

export default function NotFound() {
  return (
    <div>
      Oopss!! error page,{" "}
      <a href="/" className="underline">
        back to main
      </a>
    </div>
  );
}
